<template>
  <div class="register">
    <div class="container">
      <div class="content">
        <Row class="height">
          <Col class-name="height logoHeight" :lg="12" :md="24" :sm="24" :xs=24>
            <img class="bigLogo" src="/img/logo.png" alt="">
          </Col>
          <Col class-name="height formHeight" :lg="12" :md="24" :sm="24" :xs=24>
            <div class="formWrap">
              <div class="inlineBlockWrap">
                <div class="inlineBlock myBar">
                  <p class="registerTitle">欢迎使用设计瑰谷</p>
                  <div class="center">
                    <div class="tabTitle">
                      <span class="titleItem" 
                        :class="{'titleActive': loginWay=='pwd'}"
                        @click="changeWay('pwd')">账号密码登录</span>
                    </div>
                    <div class="tabTitle">
                      <span class="titleItem" 
                        :class="{'titleActive': 
                        loginWay=='msg'}"
                        @click="changeWay('msg')">短信验证码登录</span>
                    </div>
                  </div>
                  <Form ref="login" :model="info" hide-required-mark class="formStyle">
                    <FormItem prop="loginName" label="手机" :rules="loginNameRules">
                      <Input type="tel" v-model="info.loginName"></Input>
                    </FormItem>
                    <template v-if="loginWay=='msg'">
                      <FormItem key="msg" prop="key1" label="短信验证码" :rules="keyRules">
                        <Input type="text" class="msgCodeInput" v-model="info.key1" @on-enter="login"></Input>
                        <Button shape="circle" :disabled="codeBtnText!='获取验证码'" class="defaultBtn msgCodeBtn" @click="sendMsgCode">{{codeBtnText}}</Button>
                      </FormItem>
                    </template>
                    <template v-if="loginWay=='pwd'">
                      <FormItem key="pwd" prop="key2" label="登录密码" :rules="keyRules">
                        <Input type="password" v-model="info.key2" @on-enter="login"></Input>
                      </FormItem>
                    </template>
                  </Form>
                  <Button shape="circle" long :loading="submitting" class="defaultBtn" @click="login">登录</Button>
                  <div class="clearfixed">
                    <a :href="`${officialWebsiteUrl}#/register?fromUrl=${websiteBaseUrl}login`" target="_blank" class="blackHref">还没有账号，去注册</a>
                    <router-link to="/reset_password" class="floatRight blackHref">找回密码</router-link>
                  </div>
                  <!-- <Divider class="lightColor" size="small">合作机构账号登录</Divider> -->
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </div>
</template>

<script>
import centerService from "@/api/center";
import { websiteBaseUrl, officialWebsiteUrl } from "@/config/env";
export default {
  name: "Index",
  data() {
    return {
      websiteBaseUrl: websiteBaseUrl,
      officialWebsiteUrl: officialWebsiteUrl,
      loginWay: "pwd",
      info: {},
      loginNameRules: [
        {
          required: true,
          pattern: /^1[3456789]\d{9}$/,
          message: "请输入手机号码",
          trigger: "blur"
        }
      ],
      keyRules: [
        { required: true, message: "内容不能为空", trigger: "blur,change" }
      ],
      submitting: false,
      codeBtnText: "获取验证码"
    };
  },
  mounted() {},
  methods: {
    // 更换登录方式
    changeWay(val) {
      if (this.loginWay == val) {
        return;
      }
      this.info.key = null;
      this.loginWay = val;
    },
    // 登录
    login() {
      this.$refs.login.validate(valid => {
        if (valid) {
          this.submitting = true;
          let condition = { loginName: this.info.loginName };
          if (this.loginWay == "pwd") {
            condition.key = this.info.key2;
          }
          if (this.loginWay == "msg") {
            condition.key = this.info.key1;
          }
          condition.accountType = "DESIGNER";
          centerService
            .login(condition)
            .then(res => {
              if (res.errorNo == 0) {
                this.$store.commit("setToken", res.data.accessToken);
                let user = res.data.user;
                this.$store.commit("setUserInfo", user);
                window.localStorage.setItem("login_time", new Date().getTime());
                // 跳转到什么页面
                // let fromUrl = this.$route.query.fromUrl || '/dragon_star/index'
                let fromUrl = this.$route.query.fromUrl;
                fromUrl == "undefined" && (fromUrl = null);
                fromUrl = fromUrl || "/dragon_star/index";
                this.$router.push(fromUrl);
                // this.$Message.info({content:'为了更好的使用体验，建议您使用谷歌浏览器且勿使用无痕模式。',duration:3})
                // this.$Modal.info({
                //   title: '友情提示',
                //   content: '为了更好的使用体验，建议您使用谷歌浏览器且勿使用无痕模式。'
                // });
                let ua = navigator.userAgent;
                // console.log(ua)
                let browserSupport = false;
                if (
                  ua.indexOf("Chrome") > -1 ||
                  ua.indexOf("Safari") > -1 ||
                  ua.indexOf("Firefox") > -1
                ) {
                  browserSupport = true;
                }
                if (!browserSupport) {
                  this.$Modal.info({
                    title: "友情提示",
                    content:
                      "为了更好的使用体验，建议您使用谷歌浏览器且勿使用无痕模式。国产浏览器建议使用极速模式不要使用兼容模式。"
                  });
                } else {
                  // this.$Message.info({content:'为了更好的使用体验，建议您使用谷歌浏览器且勿使用无痕模式。',duration:3})
                }
              } else {
                // this.$Message.error(res.errorMessage)
                // this.$Message.error('用户不存在或密码错误')
                if (res.errorNo == 9013) {
                  if (
                    res.errorMessage.split(",").length > 1 &&
                    res.errorMessage.split(",")[0] == 5054
                  ) {
                    this.$Message.error("用户不存在");
                  } else {
                    this.$Message.error("密码错误");
                  }
                } else {
                  this.$Message.error(res.errorMessage);
                }
              }
              this.submitting = false;
            })
            .catch(err => {
              this.submitting = false;
              // this.$Message.error(err.message)
              console.log(err.message);
              this.$Message.error("连接服务器失败，请稍后再试");
            });
        } else {
          this.$Message.error("请输入正确的手机号和密码(验证码)");
        }
      });
    },
    // 获取验证码
    sendMsgCode() {
      this.$refs.login.validateField("loginName", valid => {
        if (valid) {
          this.$Message.error("请输入正确的手机号");
        } else {
          let condition = {
            smsType: "LOGIN",
            mobilePhone: this.info.loginName
          };
          let timer = null;
          centerService.sendCaptcha(condition).then(res => {
            if (res.errorNo == 0) {
              let time = 60,
                _this = this;
              timer = setInterval(function() {
                time--;
                _this.codeBtnText = `获取验证码${time}`;
                if (time == 0) {
                  clearInterval(timer);
                  _this.codeBtnText = "获取验证码";
                }
              }, 1000);
            } else {
              this.$Message.error(res.errorMessage);
            }
          });
          this.$once("hook:beforeDestroy", () => {
            clearInterval(timer);
            this.codeBtnText = "获取验证码";
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.register {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url(/img/registerBg.jpg) no-repeat center;
  background-size: cover;
  padding: 50px 0;

  .container {
    height: 100%;
  }

  .content {
    position: relative;
    border-radius: 10px;
    height: 100%;
    background: url(/img/registerBg2.jpg) no-repeat left #ffffff;
    background-size: 75% 100%;
    padding: 15px 50px;

    .height {
      height: 100%;
    }
    .bigLogo {
      position: absolute;
      top: 50%;
      left: 20%;
      max-width: 80%;
    }
    .registerTitle {
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 10px;
    }
    .formWrap {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #ffffff;
      box-shadow: 6px 6px 15px 3px rgba($color: #506b78, $alpha: 0.5);
      border-top: 2px solid #00b7ee;
      height: 100%;
      overflow: hidden;

      .inlineBlockWrap {
        height: 100%;
        width: 200%;
        white-space: nowrap;
        font-size: 0;
      }
      .inlineBlock {
        font-size: 14px;
        width: 50%;
        max-height: 100%;
        display: inline-block;
        white-space: normal;
        padding: 15px 30px 30px;
        vertical-align: top;
        overflow: auto;
      }
      .msgCodeInput {
        padding-right: 110px;
      }
      .defaultBtn {
        margin: 20px 0;
      }
      .msgCodeBtn {
        text-align: center;
        position: absolute;
        right: 0;
        // top: 24px;
        width: 110px;
        padding: 0;
        margin: 0;
      }
      .blackHref {
        color: #221715;
      }
      .floatRight {
        float: right;
      }
    }
  }
}
.tabTitle {
  display: inline-block;
  width: 48%;
  color: #262626;
  margin: 5px 0 10px;
}
.titleItem {
  display: inline-block;
  padding-bottom: 5px;
  cursor: pointer;
}
.titleActive {
  font-weight: 600;
  border-bottom: 1px solid #262626;
  cursor: default;
}
@media (max-width: 768px) {
  .register .content {
    padding: 15px;

    .logoHeight {
      height: 20%;

      .bigLogo {
        max-width: 50%;
        transform: translateY(-50%);
      }
    }
    .formHeight {
      height: 80%;
    }
  }
}
</style>